import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useCallback } from 'react';
import { feedback } from 'react-feedbacker';
import { CombinedError, useMutation } from 'urql';

import { useFeedbackMessages } from '@/bits';
import { FileDrop, useFileDropFile } from '@/bits/file-drop/component';
import { playerDocuments_document } from '@/blocks/player-documents-block/usePlayerDocuments';
import {
  Button,
  Card,
  CardCloseButton,
  CardOptions,
  CheckboxField,
  ErrorMessage,
  Form,
  SelectField,
  SubmitButton,
  TextareaField,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { FundingOption } from '@/globalTypes';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  ApproveFundingDocumentMutation,
  ApproveFundingDocumentMutationVariables,
} from './__generated__/ApproveFundingDocumentMutation';
import {
  FundingDocumentUploadedMutation,
  FundingDocumentUploadedMutationVariables,
} from './__generated__/FundingDocumentUploadedMutation';
import {
  PlayerDocumentUploadFormStaticQuery,
  PlayerDocumentUploadFormStaticQuery_sanityPlayerDocumentUploadForm,
} from './__generated__/PlayerDocumentUploadFormStaticQuery';
import { PlayerGenerateDocumentUrlMutation } from './__generated__/PlayerGenerateDocumentUrlMutation';
import {
  PlayerGenerateFundingDocumentUploadUrlMutation,
  PlayerGenerateFundingDocumentUploadUrlMutation_generateFundingDocumentUploadUrl,
} from './__generated__/PlayerGenerateFundingDocumentUploadUrlMutation';
import {
  UploadDocumentMutation,
  UploadDocumentMutationVariables,
} from './__generated__/UploadDocumentMutation';

const query = graphql`
  query PlayerDocumentUploadFormStaticQuery {
    sanityPlayerDocumentUploadForm {
      title {
        ...LocaleString
      }
      fundingOptionLabel {
        ...LocaleString
      }
      commentLabel {
        ...LocaleString
      }
      autoApproveLabel {
        ...LocaleString
      }
      submit {
        ...LocaleString
      }
    }
  }
`;

const generateDocumentUrlMutation = gql`
  mutation PlayerGenerateDocumentUrlMutation {
    generateDocumentUrl {
      url
      documentId
    }
  }
`;

export const generateFundingDocumentUploadUrlMutation = gql`
  mutation PlayerGenerateFundingDocumentUploadUrlMutation {
    generateFundingDocumentUploadUrl {
      url
      documentId
    }
  }
`;

const fundingDocumentUploadedMutation = gql`
  mutation FundingDocumentUploadedMutation(
    $documentId: ID!
    $playerId: ID!
    $fundingOption: FundingOption!
  ) {
    fundingDocumentUploaded(
      documentId: $documentId
      playerId: $playerId
      fundingOption: $fundingOption
    ) {
      ...PlayerDocuments_document
    }
  }
  ${playerDocuments_document}
`;

const approveFundingDocumentMutation = gql`
  mutation ApproveFundingDocumentMutation(
    $playerId: ID!
    $documentId: ID!
    $fundingOption: FundingOption!
    $comment: String
  ) {
    approveFundingDocument(
      documentId: $documentId
      playerId: $playerId
      fundingOption: $fundingOption
      comment: $comment
    ) {
      ...PlayerDocuments_document
    }
  }
  ${playerDocuments_document}
`;

export const uploadDocumentMutation = gql`
  mutation UploadDocumentMutation(
    $documentId: ID!
    $playerId: ID!
    $comment: String!
  ) {
    uploadDocumentV2(
      documentId: $documentId
      playerId: $playerId
      comment: $comment
    ) {
      ...PlayerDocuments_document
    }
  }
  ${playerDocuments_document}
`;

const Layout: FC<{
  form: PlayerDocumentUploadFormStaticQuery_sanityPlayerDocumentUploadForm;
}> = ({ form, children }) => {
  const { t } = useTranslate();
  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">{children}</div>
    </Card>
  );
};

type Props = {
  playerId: string;
};

type UploadReason = 'kyc' | 'sow';

type FormValues = {
  comment: string;
  fundingOption: FundingOption;
  autoApprove: boolean;
};

const useGetDocumentUploadInfo = (reason: UploadReason) => {
  const [, generateDocumentUrl] =
    useMutation<PlayerGenerateDocumentUrlMutation>(generateDocumentUrlMutation);

  const [, generateFundingDocumentUrl] = useMutation<
    PlayerGenerateFundingDocumentUploadUrlMutation,
    PlayerGenerateFundingDocumentUploadUrlMutation_generateFundingDocumentUploadUrl
  >(generateFundingDocumentUploadUrlMutation);

  const getDocumentUploadInfo = useCallback(async () => {
    if (reason === 'kyc') {
      return generateDocumentUrl().then((res) => ({
        error: res.error,
        documentInfo: res.data?.generateDocumentUrl,
      }));
    }
    return generateFundingDocumentUrl().then((res) => ({
      error: res.error,
      documentInfo: res.data?.generateFundingDocumentUploadUrl,
    }));
  }, [generateDocumentUrl, generateFundingDocumentUrl, reason]);

  return getDocumentUploadInfo;
};

const useMakeUpload = (reason: UploadReason) => {
  const [, fundingDocumentUploaded] = useMutation<
    FundingDocumentUploadedMutation,
    FundingDocumentUploadedMutationVariables
  >(fundingDocumentUploadedMutation);

  const [, approveFundingDocument] = useMutation<
    ApproveFundingDocumentMutation,
    ApproveFundingDocumentMutationVariables
  >(approveFundingDocumentMutation);

  const [, uploadDocument] = useMutation<
    UploadDocumentMutation,
    UploadDocumentMutationVariables
  >(uploadDocumentMutation);

  const upload = useCallback(
    async ({
      playerId,
      documentId,
      values,
    }: {
      playerId: string;
      documentId: string;
      values: FormValues;
    }) => {
      if (reason === 'kyc') {
        return uploadDocument({
          playerId,
          documentId,
          comment: values.comment,
        }).then((res) => ({
          error: res.error,
        }));
      }

      const uploadRes = await fundingDocumentUploaded({
        documentId,
        playerId,
        fundingOption: values.fundingOption,
      });

      if (uploadRes.error) {
        return {
          error: uploadRes.error,
        };
      }

      if (values.autoApprove) {
        return approveFundingDocument({
          playerId,
          documentId,
          fundingOption: values.fundingOption,
          comment: values.comment,
        }).then((res) => ({
          error: res.error,
        }));
      }

      return {
        error: undefined,
      };
    },
    [approveFundingDocument, fundingDocumentUploaded, reason, uploadDocument],
  );

  return upload;
};

const PlayerDocumentUploadForm: FC<
  Props & {
    reason: UploadReason;
    form: PlayerDocumentUploadFormStaticQuery_sanityPlayerDocumentUploadForm;
  }
> = ({ playerId, form, reason }) => {
  const feedbackMessages = useFeedbackMessages();
  const { close } = useModalContext();
  const { file, dispatch } = useFileDropFile();
  const { t } = useTranslate();
  const isMounted = useIsMounted();
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const getDocumentUploadInfo = useGetDocumentUploadInfo(reason);
  const triggerUpload = useMakeUpload(reason);

  const [isUploading, setIsUploading] = useState(false);

  const defaultValues: FormValues = {
    comment: '',
    fundingOption: '' as FundingOption,
    autoApprove: reason === 'sow',
  };

  const onSubmit = async (values: FormValues) => {
    if (!file) {
      return;
    }

    const handleError = (err: Nullable<CombinedError>) => {
      if (err?.message && isMounted) {
        setErrorMessage(err.message);
      }
      if (err?.message) {
        throw new Error('Mutation error');
      }
    };

    try {
      setErrorMessage(null);
      setIsUploading(true);

      const docInfo = await getDocumentUploadInfo();

      handleError(docInfo.error);

      const { documentId, url } = docInfo.documentInfo!;

      await fetch(url, {
        body: file,
        method: 'PUT',
        headers: { 'Content-Type': file.type as string },
      });

      const res = await triggerUpload({
        documentId,
        playerId,
        values,
      });

      const errorMessage = res.error?.message;

      if (errorMessage && isMounted) {
        setErrorMessage(errorMessage);
        setIsUploading(false);
      }

      if (errorMessage) {
        return;
      }

      close?.();

      feedback.success(t(feedbackMessages.success));
    } finally {
      if (isMounted) {
        setIsUploading(false);
      }
    }
  };

  return (
    <Form
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      className="grid grid-cols-1 gap-6"
    >
      <FileDrop
        dispatch={dispatch}
        file={file}
        accept="image/jpeg, image/png, application/pdf"
        setErrorMessage={setErrorMessage}
      />
      {reason === 'sow' && (
        <SelectField
          title={t(form.fundingOptionLabel)}
          name="fundingOption"
          options={Object.values(FundingOption).map((fundingOption) => ({
            label: fundingOption,
            value: fundingOption,
          }))}
          required
        />
      )}
      <TextareaField
        name="comment"
        id="PlayerDocumentUploadForm__comment"
        title={t(form.commentLabel)}
        rows={3}
        required={reason === 'kyc'}
      />
      {reason === 'sow' && (
        <CheckboxField
          name="autoApprove"
          id="PlayerDocumentUploadForm__autoApprove"
          title={t(form.autoApproveLabel)}
        />
      )}
      <ErrorMessage message={errorMessage} />
      <SubmitButton value={t(form.submit)} disabled={isUploading} />
    </Form>
  );
};

const PlayerDocumentUploadFormWrapper = (props: Props) => {
  const [reason, setReason] = useState<UploadReason | undefined>(undefined);

  const staticData = useStaticQuery<PlayerDocumentUploadFormStaticQuery>(query);
  const form = staticData.sanityPlayerDocumentUploadForm;

  if (!form) {
    return null;
  }

  if (reason) {
    return (
      <Layout form={form}>
        <PlayerDocumentUploadForm {...props} form={form} reason={reason} />
      </Layout>
    );
  }

  return (
    <Layout form={form}>
      <div className="grid grid-cols-2 gap-3">
        <Button
          type="button"
          variant="primary"
          onClick={() => setReason('kyc')}
        >
          KYC
        </Button>
        <Button
          type="button"
          variant="primary"
          onClick={() => setReason('sow')}
        >
          SOW
        </Button>
      </div>
    </Layout>
  );
};

export default PlayerDocumentUploadFormWrapper;
